import Aegis from 'aegis-web-sdk';

const env =
  process.env.NODE_ENV === 'development' ? 'development' : 'production';

const ext1 = JSON.stringify({
  gitCommitId: process.env.GIT_COMMIT_ID,
});

const Monitoring = process.env.MONITORING
  ? new Aegis({
      id: 'YrmzVIbWxOY0REY7Kl',
      hostUrl: 'https://rumt-zh.com',
      env,
      ext1,
    })
  : {
      // 未开启监控时，返回一个空对象，保持接口一致性
      setConfig: () => {},
      info: () => {},
      error: () => {},
    };

export default Monitoring;
