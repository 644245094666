import Monitoring from '@/Monitoring';
import pino from 'pino';

const Logger = pino({
  level: process.env.NODE_ENV === 'development' ? 'debug' : 'info',
  browser: process.env.MONITORING
    ? {
        write: {
          info(o) {
            // eslint-disable-next-line no-console
            console.info(o);

            Monitoring.info(o);
          },
          warn(o) {
            // eslint-disable-next-line no-console
            console.warn(o);

            // pino包含level属性标识日志级别，详见：https://github.com/pinojs/pino/blob/main/docs/api.md#loggerlevel-string-gettersetter
            Monitoring.info(o);
          },
          error(o) {
            // eslint-disable-next-line no-console
            console.error(o);

            Monitoring.error(o);
          },
        },
      }
    : { asObject: process.env.NODE_ENV === 'production' },
});

export default Logger;
