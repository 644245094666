import { App, Modal, message, notification } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { NotificationInstance } from 'antd/es/notification/interface';

let messageInstance: MessageInstance;
let notificationInstance: NotificationInstance;
let modalInstance: Omit<ModalStaticFunctions, 'warn'>;

export function getMessage() {
  return messageInstance || message;
}

export function getNotification() {
  return notificationInstance || notification;
}

export function getModal() {
  return modalInstance || Modal;
}

export function useNotice() {
  const app = App.useApp();
  messageInstance = app.message;
  notificationInstance = app.notification;
  modalInstance = app.modal;
}
