import TencentEventTracker from './event-tracker/TencentEventTracker';

const EventTracker = new TencentEventTracker({
  projectId: 'sasan-courseware',
  topicId: process.env.TENCENT_CLOUD_CLS_TOPIC_ID!,
  endpoint: process.env.TENCENT_CLOUD_CLS_ENDPOINT!,
  debug: process.env.EVENT_TRACKER_DEBUG === 'true',
});

export default EventTracker;
