import '@/Monitoring';

import { handleRequestError } from '@/error-handler';

import 'simplebar-react/dist/simplebar.min.css';

import { loginPrefix } from '@/constants/route';
import { FullscreenLoading } from '@/fullscreen-loading';
import useUserStore from '@/stores/useUserStore';
import anewSignIn from '@/utils/anewSignIn';
import Axios from '@/utils/Axios';
import { useNotice } from '@/utils/notice';
import { injectGlobal } from '@emotion/css';
import { defineApp } from '@umijs/max';
import { App, ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
  #root {
    height: 100%;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
`;

function Providers(props: PropsWithChildren) {
  const { children } = props;

  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: process.env.NODE_ENV === 'production',
        onError: (error) => {
          if (error.isAxiosError) {
            handleRequestError(error);
          }
        },
      }}
    >
      <ConfigProvider
        locale={zhCN}
        theme={{
          components: {
            Table: {
              headerSplitColor: 'transparent',
            },
            Select: {
              colorBorder: '#d9d9d986',
            },
          },
        }}
      >
        <FullscreenLoading />

        {children}
      </ConfigProvider>
    </SWRConfig>
  );
}

function loadSchool() {
  Axios.get<
    {
      current: boolean;
      id: number;
      name: string;
      role: string;
    }[]
  >('/teachers/schools').then(({ data }) => {
    useUserStore.setState({
      schools: data.map((v) => ({
        id: String(v.id),
        name: v.name,
      })),
      currentSchoolId: String(data.find((v) => v.current)?.id || ''),
    });
  });
}

let alreadyLoadedUserProfile = false;

function loadUserProfile() {
  if (alreadyLoadedUserProfile && useUserStore.getState().isFilled()) return;

  Axios.get<{
    avatar?: null | string;
    currentSchoolRoleName?: string;
    id: number;
    identity: 'staff' | 'student' | 'teacher' | 'parent';
    name: string;
    prefer: boolean;
  }>('/users/profile').then(({ data }) => {
    const role = (() => {
      if (data.identity === 'teacher') {
        return 'teacher';
      }
      if (data.identity === 'student') {
        return 'student';
      }
      return '';
    })();
    useUserStore.setState({
      id: String(data.id),
      name: data.name,
      picture: data.avatar || '',
      role,
      prefer: data.prefer || false,
      schoolPost: data.currentSchoolRoleName || '',
    });

    if (role === 'teacher') {
      loadSchool();
    }

    alreadyLoadedUserProfile = true;
  });
}

function AppExtends() {
  useNotice();
  return null;
}

export default defineApp({
  rootContainer: (container) => (
    <Providers>
      <App>
        <AppExtends />
        {container}
      </App>
    </Providers>
  ),
  onRouteChange: () => {
    if (
      ![new RegExp(`/${loginPrefix}`)].every((v) =>
        v.test(window.location.pathname),
      )
    ) {
      if (localStorage.getItem('token')) {
        loadUserProfile();
      } else {
        anewSignIn();
      }
    }
  },
});
