import { uuidv7 } from 'uuidv7';

interface User {
  id: string;
  name: string;
}

export default abstract class BaseEventTracker {
  protected sessionId: string;

  protected user?: User;

  /**
   * 构造函数
   * @param projectId 用于区分不同的项目，例如课件平台，学生端，教师端会有不同的项目Id
   * @param debug 是否开启调试模式。开发环境下，调试模式会打印日志
   */
  constructor(
    protected projectId: string,
    protected debug: boolean,
  ) {
    this.projectId = projectId;

    this.sessionId = uuidv7();
  }

  setUser(user: User) {
    this.user = user;
  }

  abstract collectEvent(
    name: string,
    params: Record<string, unknown>,
  ): Promise<boolean>;

  protected createHeader(): Record<string, unknown> {
    return {
      project_id: this.projectId,
    };
  }

  protected createEvent(name: string, params: Record<string, unknown>) {
    const now = Date.now();
    return {
      name,
      params,
      created_at: now,
      session_id: this.sessionId,
    };
  }
}
