import Logger from '@/utils/Logger';
import TencentEventTracker from './event-tracker/TencentEventTracker';

const EventTracker = process.env.TENCENT_CLOUD_CLS_ENDPOINT
  ? new TencentEventTracker({
      projectId: 'sasan-courseware',
      topicId: process.env.TENCENT_CLOUD_CLS_TOPIC_ID!,
      endpoint: process.env.TENCENT_CLOUD_CLS_ENDPOINT,
      logError: (error: unknown) => {
        Logger.error({ msg: 'TencentEventTracker', err: error });
      },
      debug: process.env.EVENT_TRACKER_DEBUG === 'true',
    })
  : {
      collectEvent() {},
      setUser() {},
    };

export default EventTracker;
