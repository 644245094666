import useUserStore from '@/stores/useUserStore';
import { getMessage } from '@/utils/notice';
import { debounce } from 'throttle-debounce';

function anewSignIn(prompt?: string) {
  const signIn = () => {
    localStorage.removeItem('token');

    if (useUserStore.getState().role === 'student') {
      window.open('/login/student/sign-in', '_self');
    } else {
      window.open('/login/teacher/sign-in', '_self');
    }
  };

  if (prompt) {
    getMessage().error(prompt, 3, () => {
      signIn();
    });
  } else {
    signIn();
  }
}

export default debounce(3000, anewSignIn, {
  atBegin: true,
});
