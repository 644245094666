import useFullscreenLoadingStore from './store';

export default function showFullscreenLoading() {
  useFullscreenLoadingStore.setState({ loading: true });

  const hideFullscreenLoading = () => {
    useFullscreenLoadingStore.setState({ loading: false });
  };

  return hideFullscreenLoading;
}
