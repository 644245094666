import Monitoring from '@/Monitoring';
import EventTracker from '@/utils/EventTracker';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

export type Role = 'teacher' | 'student' | '';

export type School = { id: string; name: string };

export type UserState = {
  id: string;
  name: string;
  picture: string;
  role?: Role;
  schools: School[];
  currentSchoolId: string;
  schoolPost: string;
  prefer: boolean;
  isFilled: () => boolean;
};

export const initialState = {
  id: '',
  name: '',
  picture: '',
  role: '' as const,
  schools: [],
  currentSchoolId: '',
  schoolPost: '',
  prefer: false,
};

const useUserStore = create<UserState>()(
  persist(
    subscribeWithSelector((set, get) => ({
      ...initialState,

      isFilled: () => Boolean(get().id),
    })),
    { name: 'user_store' },
  ),
);

useUserStore.subscribe(
  (state) => [state.id, state.name],
  ([id, name]) => {
    Monitoring.setConfig({
      uin: id,
    });
    EventTracker.setUser({ id, name });
  },
);

export default useUserStore;
