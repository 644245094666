import axios from 'axios';

const Axios = axios.create();

Axios.interceptors.request.use((config) => {
  config.baseURL = process.env.API_BASE_URL;
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export default Axios;
