import anewSignIn from '@/utils/anewSignIn';
import { getMessage } from '@/utils/notice';
import { AxiosError } from 'axios';

export class IgnoreError extends Error {
  constructor() {
    super('忽略错误');
  }
}

export function handleRequestError(error: AxiosError<any>) {
  const { response } = error;
  if (response) {
    switch (response.status) {
      case 401: {
        anewSignIn('身份验证失败，请重新登录');
        break;
      }
      default: {
        getMessage().error(response.data?.message || '请求失败');
        break;
      }
    }
  } else {
    getMessage().error('网络异常');
  }
}

window.addEventListener('unhandledrejection', (event) => {
  if (event.reason instanceof IgnoreError) {
    event.stopImmediatePropagation();
    event.preventDefault();
  } else if (event.reason?.isAxiosError) {
    handleRequestError(event.reason);
  }
});
