import { create } from 'zustand';

export type FullscreenLoadingState = {
  loading: boolean;
};

const useFullscreenLoadingStore = create<FullscreenLoadingState>()(() => ({
  loading: false,
}));

export default useFullscreenLoadingStore;
