// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","id":"1"},"2":{"path":"/login/teacher/sign-in","id":"2"},"3":{"path":"/login/student/sign-in","id":"3"},"4":{"id":"4"},"5":{"path":"/courseware","parentId":"4","id":"5"},"6":{"path":"/guideline","parentId":"4","id":"6"},"7":{"path":"/scene","parentId":"4","id":"7"},"8":{"path":"/scaffold","parentId":"4","id":"8"},"9":{"path":"/movement","parentId":"4","id":"9"},"10":{"path":"/courseware/detail/:id","id":"10"},"11":{"path":"/courseware/unit/:id","id":"11"},"12":{"path":"/viewer","id":"12"},"13":{"path":"/player/:coursewareId","id":"13"},"14":{"path":"/settings","id":"14"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "Home" */'@/Home.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "login__teacher__SignIn__index" */'@/login/teacher/SignIn/index.ts')),
'3': React.lazy(() => import(/* webpackChunkName: "login__student__SignIn__index" */'@/login/student/SignIn/index.ts')),
'4': React.lazy(() => import(/* webpackChunkName: "components__CentralLayout__index" */'@/components/CentralLayout/index.ts')),
'5': React.lazy(() => import(/* webpackChunkName: "courseware__Courseware__index" */'@/courseware/Courseware/index.ts')),
'6': React.lazy(() => import(/* webpackChunkName: "Guideline__index" */'@/Guideline/index.ts')),
'7': React.lazy(() => import(/* webpackChunkName: "Scene__index" */'@/Scene/index.ts')),
'8': React.lazy(() => import(/* webpackChunkName: "Scaffold__index" */'@/Scaffold/index.ts')),
'9': React.lazy(() => import(/* webpackChunkName: "Movement__index" */'@/Movement/index.ts')),
'10': React.lazy(() => import(/* webpackChunkName: "courseware__CoursewareDetail__index" */'@/courseware/CoursewareDetail/index.ts')),
'11': React.lazy(() => import(/* webpackChunkName: "courseware__CoursewareUnit__index" */'@/courseware/CoursewareUnit/index.ts')),
'12': React.lazy(() => import(/* webpackChunkName: "Viewer__index" */'@/Viewer/index.ts')),
'13': React.lazy(() => import(/* webpackChunkName: "Player__index" */'@/Player/index.ts')),
'14': React.lazy(() => import(/* webpackChunkName: "settings__index" */'@/settings/index.ts')),
},
  };
}
