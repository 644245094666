import {
  AsyncClient,
  Log,
  LogGroup,
  PutLogsRequest,
} from 'tencentcloud-cls-sdk-js-web';
import { uuidv7 } from 'uuidv7';
import { UAParser } from 'ua-parser-js';
import Logger from '../Logger';
import BaseEventTracker from './BaseEventTracker';

/**
 * 基于 腾讯云日志服务（CLS） 的事件追踪器
 */
export default class TencentEventTracker extends BaseEventTracker {
  /**
   * 腾讯云日志服务里可匿名上报的日志主题ID
   */
  private clsTopicId;

  private clsClient: AsyncClient;

  private userAgent: UAParser.IResult;

  constructor({
    projectId,
    topicId,
    endpoint,
    debug,
  }: {
    projectId: string;
    topicId: string;
    endpoint: string;
    debug?: boolean;
  }) {
    super(projectId, debug === true);

    this.clsTopicId = topicId;

    this.clsClient = new AsyncClient({
      endpoint,
      retry_times: 5, // 重试次数，目前设置固定值
    });

    this.userAgent = new UAParser(navigator.userAgent).getResult();
  }

  async collectEvent(name: string, params: Record<string, unknown>) {
    const event = this.createEvent(name, params);

    const log = new Log(event.created_at);
    log.addContent('event', JSON.stringify(event));

    const header = this.createHeader();
    log.addContent('header', JSON.stringify(header));

    if (this.user) {
      log.addContent('user', JSON.stringify(this.user));
    }

    const logGroup = new LogGroup('');
    logGroup.addLog(log);

    if (process.env.NODE_ENV === 'development') {
      if (this.debug) {
        Logger.debug('[event-tracker] collectEvent %o', logGroup);
      }
      return true; // 开发环境下不发送日志到服务器
    }

    const request = new PutLogsRequest(this.clsTopicId, logGroup);

    try {
      await this.clsClient.PutLogs(request);
      return true;
    } catch (err) {
      Logger.error(err);
      return false;
    }
  }

  protected override createHeader(): Record<string, unknown> {
    const baseHeader = super.createHeader();
    return {
      ...baseHeader,
      instance_id: TencentEventTracker.getInstanceId(),
      user_agent: this.userAgent,
    };
  }

  /**
   * 存储在浏览器的用户唯一标识，用户清空浏览器缓存后会重新生成
   */
  protected static getInstanceId() {
    const key = 'event_tracker_instance_id';
    let instanceId = localStorage.getItem(key);
    if (!instanceId) {
      instanceId = uuidv7();
      localStorage.setItem(key, instanceId);
    }
    return instanceId;
  }
}
